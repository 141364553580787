import React, { MouseEvent, useState, FC } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useInput,
  useDateRange,
  useApisManagersEmployeeSurveysUpdate,
  useMultiDivisionMultiSectionDropdown,
  useBoolean,
  useProvidersCurrentEmployee,
  useDropdown,
  useEmployeeSurveyQuestionForms,
} from "~/hooks";

import { Button, NoContentMessage } from "~/components/atoms";
import { DragDropProvider } from "~/components/atoms";
import { VerticalProgressLine } from "~/components/atoms";
import {
  LabelWithTextField,
  RangeDatePicker,
  AddFormFieldButton,
  TabList,
  NameOrderableForm,
  LabelWithDropDownField,
  LabelWithTextArea,
} from "~/components/molecules";
import {
  AllDistributionCheckboxMultiParentsWithMultiChildrenDropdownField,
  EmployeeSurveyQuestionForm,
} from "~/components/organisms";

import {
  PublicStatus,
  BusinessDivisionType,
  BusinessSectionType,
  EmployeeSurveyWithQuestionsType,
  AllAnswerShareOptions,
} from "~/domains";

type PropsType = {
  selectableBusinessDivisions: BusinessDivisionType[];
  selectableBusinessSections: BusinessSectionType[];
  employeeSurvey: EmployeeSurveyWithQuestionsType;
};

export const EmployeeSurveyWithQuestionsForm: FC<PropsType> = ({
  selectableBusinessDivisions,
  selectableBusinessSections,
  employeeSurvey,
}: PropsType) => {
  const navigate = useNavigate();

  const { mutate: putEmployeeSurvey, isLoading: isUpdating } =
    useApisManagersEmployeeSurveysUpdate();
  const { currentEmployee } = useProvidersCurrentEmployee();
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  const [{ value: title, onChange: onChangeTitle }] = useInput(
    employeeSurvey.title,
  );
  const [{ value: description, onChange: onChangeDescription }] = useInput(
    employeeSurvey.description,
  );
  const [dateRange] = useDateRange([
    new Date(employeeSurvey.startDate),
    new Date(employeeSurvey.endDate),
  ]);

  const [dropdownAnswerShareOption] = useDropdown({
    initialValue: {
      id: employeeSurvey.answerShareOption.id.toString(),
      name: employeeSurvey.answerShareOption.name,
    },
  });

  const {
    isSubmitting,
    employeeSurveyQuestions,
    onChangeQuestion,
    addEmployeeSurveyQuestion,
    removeEmployeeSurveyQuestion,
    saveEmployeeSurveyQuestion,
    changeDisplayOrder,
    onChangeFile,
    onChangeQuestionType,
    onChangeSetNotReadOnly,
    onChangeSetReadOnly,
    addEmployeeSurveyQuestionOption,
    removeEmployeeSurveyQuestionOption,
    onChangeEmployeeSurveyQuestionOptionText,
    onChangeEmployeeSurveyQuestionOptionPoint,
  } = useEmployeeSurveyQuestionForms({
    employeeSurveyId: employeeSurvey.id,
    employeeSurveyQuestions: employeeSurvey.employeeSurveyQuestions,
  });

  const {
    isChecked: allBusinessSectionsDistribution,
    handleChange: onChangeAllBusinessSectionsDistribution,
  } = useBoolean(employeeSurvey.allBusinessSectionsDistribution);

  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
  } = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: selectableBusinessDivisions || [],
    selectableSections: selectableBusinessSections || [],
    defaultDivisions: employeeSurvey.businessDivisions,
    defaultSections: employeeSurvey.businessSections,
  });

  const handlePublishSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const isConfirm = confirm(
      "作成・編集した「各項目」を「保存」しなければ反映されません。配布を実行してよろしいですか？",
    );
    if (!isConfirm) return;

    handleSubmit(PublicStatus.PUBLISHED.id);
  };

  const handleDraftSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const isConfirm = confirm(
      "作成・編集した「各項目」を「保存」しなければ反映されません。配布を実行してよろしいですか？",
    );
    if (!isConfirm) return;

    handleSubmit(PublicStatus.DRAFTED.id);
  };

  const handleSubmit = (publicStatusId: number) => {
    const body = {
      businessSectionIds: optionSelectedSections.map((section) => section.id),
      title,
      startDate: dateRange.formattedStartDate || "",
      endDate: dateRange.formattedEndDate || "",
      publicStatusId,
      allBusinessSectionsDistribution,
      description,
      answerShareOptionId: dropdownAnswerShareOption.dropdownValue?.value || "",
    };
    putEmployeeSurvey(
      {
        id: employeeSurvey.id,
        body,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          navigate("/managers/employee_surveys");
        },
      },
    );
  };

  const isCurrentTabForm = selectedTabIndex === 0;
  const savedEmployeeSurveyQuestions = employeeSurveyQuestions.filter(
    (employeeSurveyQuestion) => !employeeSurveyQuestion.isNew,
  );
  return (
    <>
      <div className="space-y-6">
        <AllDistributionCheckboxMultiParentsWithMultiChildrenDropdownField
          allParents={selectableDivisions}
          parentsValue={selectedDivisions}
          parentsOnChange={onDivisionChange}
          parentRequired={true}
          parentLabel="配布先: 部署"
          allChildren={optionSelectableSections}
          childrenValue={optionSelectedSections}
          childrenOnChange={onSectionChange}
          childRequired={true}
          childLabel="配布先: 課"
          allBusinessSectionsDistribution={allBusinessSectionsDistribution}
          onChangeAllBusinessSectionsDistribution={
            onChangeAllBusinessSectionsDistribution
          }
          currentEmployeeRole={currentEmployee?.employeeRole}
        />
        <LabelWithTextField
          labelText="タイトル"
          type="text"
          name="title"
          placeholder="タイトル"
          required
          value={title}
          onChange={onChangeTitle}
        />
        <LabelWithTextArea
          labelText="説明"
          name="description"
          placeholder="説明"
          required
          value={description}
          onChange={onChangeDescription}
        />
        <LabelWithDropDownField
          labelText="共有に追加"
          required
          name="dropdownAnswerShareOption"
          options={AllAnswerShareOptions.map((status) => ({
            value: status.id.toString(),
            label: status.name,
          }))}
          value={dropdownAnswerShareOption.dropdownValue}
          onChange={dropdownAnswerShareOption.onChange}
        />
        <RangeDatePicker
          labelText="期間指定"
          required={true}
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          onChange={dateRange.onChange}
        />
        <TabList
          tabTexts={["作成", "並び替え"]}
          onClick={setSelectedTabIndex}
          selectedIndex={selectedTabIndex}
        />
        {isCurrentTabForm ? (
          employeeSurveyQuestions.length ? (
            <VerticalProgressLine>
              {employeeSurveyQuestions.map((employeeSurveyQuestion) => (
                <EmployeeSurveyQuestionForm
                  key={employeeSurveyQuestion.id}
                  isSubmitting={isSubmitting}
                  defaultAccordionOpen={Boolean(employeeSurveyQuestion.isNew)}
                  employeeSurveyQuestion={employeeSurveyQuestion}
                  onChangeSetNotReadOnly={() =>
                    onChangeSetNotReadOnly(employeeSurveyQuestion)
                  }
                  onChangeSetReadOnly={() =>
                    onChangeSetReadOnly(employeeSurveyQuestion)
                  }
                  removeEmployeeSurveyQuestion={() =>
                    removeEmployeeSurveyQuestion(employeeSurveyQuestion)
                  }
                  saveEmployeeSurveyQuestion={() =>
                    saveEmployeeSurveyQuestion(employeeSurveyQuestion)
                  }
                  onChangeQuestion={(newValue: string) =>
                    onChangeQuestion({
                      employeeSurveyQuestion,
                      newValue,
                    })
                  }
                  addEmployeeSurveyQuestionOption={() =>
                    addEmployeeSurveyQuestionOption(employeeSurveyQuestion)
                  }
                  removeEmployeeSurveyQuestionOption={(index: number) =>
                    removeEmployeeSurveyQuestionOption({
                      employeeSurveyQuestion,
                      index,
                    })
                  }
                  onChangeEmployeeSurveyQuestionOptionText={(
                    index: number,
                    newValue: string,
                  ) =>
                    onChangeEmployeeSurveyQuestionOptionText({
                      employeeSurveyQuestion,
                      index,
                      newValue,
                    })
                  }
                  onChangeEmployeeSurveyQuestionOptionPoint={(
                    index: number,
                    newValue: number,
                  ) =>
                    onChangeEmployeeSurveyQuestionOptionPoint({
                      employeeSurveyQuestion,
                      index,
                      newValue,
                    })
                  }
                  onChangeFile={(newValue: File) =>
                    onChangeFile({
                      employeeSurveyQuestion,
                      newValue,
                    })
                  }
                  onChangeQuestionType={(newValue) =>
                    onChangeQuestionType({
                      employeeSurveyQuestion,
                      newValue,
                    })
                  }
                />
              ))}
            </VerticalProgressLine>
          ) : (
            <NoContentMessage
              text="チェックアウトがありません"
              className="mt-4"
            />
          )
        ) : savedEmployeeSurveyQuestions.length ? (
          <DragDropProvider>
            <VerticalProgressLine>
              {savedEmployeeSurveyQuestions.map(
                (employeeSurveyQuestion, index) => (
                  <NameOrderableForm
                    key={`employeeSurveyQuestion-order-${employeeSurveyQuestion.id}`}
                    name={employeeSurveyQuestion.question}
                    index={index}
                    changeDisplayOrder={(
                      beforeIndex: number,
                      newIndex: number,
                    ) => changeDisplayOrder(beforeIndex, newIndex)}
                  />
                ),
              )}
            </VerticalProgressLine>
          </DragDropProvider>
        ) : (
          <NoContentMessage
            text="保存されたチェックアウトがありません"
            className="mt-4"
          />
        )}
        {isCurrentTabForm && (
          <AddFormFieldButton onClick={addEmployeeSurveyQuestion} />
        )}
        <div className="flex items-center space-x-4">
          <Button
            text="下書き保存"
            color="gray"
            outline
            className="w-full"
            onClick={handleDraftSubmit}
            readonly={isUpdating}
          />
          <Button
            text="配布をする"
            color="primary"
            className="w-full"
            readonly={isUpdating}
            onClick={handlePublishSubmit}
          />
        </div>
      </div>
    </>
  );
};
