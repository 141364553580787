import React, { FC } from "react";
import { useParams } from "react-router-dom";

import {
  useEmployeeSurveysSlideNavigation,
  useEmployeeSurveySubmissionForm,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { EmployeeSurveyDetail } from "~/components/organisms";
import { NotFoundIndex } from "~/components/pages";

export const MembersEmployeeSurveysShow: FC = () => {
  const { id = "" } = useParams<{ id: string }>();
  const query = new URLSearchParams(location.search);
  const businessSectionId = query.get("business_section_id") || "";

  const {
    employeeSurvey,
    isError,
    shareable,
    answers,
    isCreating,
    onChangeShareable,
    onChangeAnswer,
    handleSubmit,
  } = useEmployeeSurveySubmissionForm({
    employeeSurveyId: id,
  });

  const { navigations } = useEmployeeSurveysSlideNavigation({
    businessSectionId,
    selectedArray: [true, false],
  });

  const breadCrumbItems = [
    {
      label: "チェックアウト",
      href: `/employee_surveys?business_section_id=${businessSectionId}`,
    },
    {
      label: "入力",
      href: `/employee_surveys?business_section_id=${businessSectionId}`,
    },
  ];

  if (isError) return <NotFoundIndex />;

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent
        navigations={navigations}
        withRightSidebar
        className="bg-primary-200"
        navigationClassName="!bg-primary-200"
      >
        {employeeSurvey && (
          <EmployeeSurveyDetail
            employeeSurvey={employeeSurvey}
            shareable={shareable}
            onChangeShareable={onChangeShareable}
            answers={answers}
            onChangeAnswer={onChangeAnswer}
            handleSubmit={handleSubmit}
            isSubmitting={isCreating}
            className="mt-6"
          />
        )}
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar wrapperClassName="bg-primary-200" />
    </EmployeeLayout>
  );
};
