import React, { FC, useState } from "react";

import {
  useApisMembersEmployeeSurveySubmissionsEmployeeSurveySubmissionRepliesIndex,
  useEmployeeSurveysSlideNavigation,
  useRightSidebarState,
  useSearchMembersEmployeeSurveySubmissions,
} from "~/hooks";

import { Button, Form, FormSubmitButton, TextField } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  FilterIconWithSortMenu,
  InfiniteScroll,
  RightSidebarToggleIconWithLabel,
  TitleWithChild,
} from "~/components/molecules";
import {
  EmployeeSurveySubmissionDetail,
  EmployeeSurveySubmissionRepliesWithEmployeeSurveySubmission,
} from "~/components/organisms";

export const MembersEmployeeSurveySubmissionsIndex: FC = () => {
  const query = new URLSearchParams(location.search);
  const businessSectionId = query.get("business_section_id") || "";

  const {
    searchOpenToggle,
    setClose,
    isOpen,
    isReplyOpen,
    isSearchOpen,
    setReplyOpen,
    currentOpenLabel,
  } = useRightSidebarState("isClose");
  const [selectGetReplyId, setSelectGetReplyId] = useState<string>("");
  const { data: repliesIndexData, refetch: repliesRefetch } =
    useApisMembersEmployeeSurveySubmissionsEmployeeSurveySubmissionRepliesIndex(
      {
        employeeSurveySubmissionId: selectGetReplyId,
        config: {
          enabled: Boolean(selectGetReplyId),
        },
      },
    );

  const breadCrumbItems = [
    {
      label: "チェックアウト",
      href: `/employee_surveys?business_section_id=${businessSectionId}`,
    },
    {
      label: "共有",
      href: `/employee_survey_submissions?business_section_id=${businessSectionId}`,
    },
  ];

  const { navigations } = useEmployeeSurveysSlideNavigation({
    businessSectionId,
    selectedArray: [false, true],
  });

  const {
    sort,
    setSort,
    keyword,
    isFetching,
    fetchNextPage,
    hasNextPage,
    employeeSurveySubmissions,
    onChangeKeyword,
    onSearchSubmit,
    onConditionReset,
  } = useSearchMembersEmployeeSurveySubmissions({
    onlyShareable: true,
  });

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent
        navigations={navigations}
        withRightSidebar
        className="bg-primary-200"
        navigationClassName="!bg-primary-200"
      >
        <FilterIconWithSortMenu
          likeSort={() => setSort("likeCountDesc")}
          bookmarkSort={() => setSort("bookmarkCountDesc")}
          createdAtAscSort={() => setSort("createdAtAsc")}
          createdAtDescSort={() => setSort("createdAtDesc")}
          currentSortType={sort}
          className="mt-6"
        />
        <InfiniteScroll
          itemsLength={employeeSurveySubmissions.length}
          nextFetchFunction={fetchNextPage}
          hasMore={hasNextPage}
          isLoading={isFetching}
          className="border-t-2 border-secondary-200"
        >
          {employeeSurveySubmissions.map((submission) => (
            <EmployeeSurveySubmissionDetail
              key={submission.id}
              employeeSurveySubmission={submission}
              className="mt-12"
              openReply={() => {
                setSelectGetReplyId(submission.id);
                setReplyOpen();
              }}
            />
          ))}
        </InfiniteScroll>
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar wrapperClassName="bg-primary-200">
        <RightSidebarToggleIconWithLabel
          iconOnClick={searchOpenToggle}
          handleOnClose={setClose}
          label={currentOpenLabel}
          isOpen={isOpen}
        >
          {isSearchOpen && (
            <Form onSubmit={onSearchSubmit} className="mt-6 space-y-6">
              <TitleWithChild title="フリーワード">
                <TextField
                  value={keyword}
                  placeholder="キーワード"
                  type="search"
                  name="keyword"
                  onChange={onChangeKeyword}
                />
              </TitleWithChild>
              <div className="space-x-4 flex">
                <Button
                  text="リセット"
                  color="gray"
                  outline
                  className="w-full"
                  onClick={onConditionReset}
                />
                <FormSubmitButton
                  value="検索する"
                  color="primary"
                  className="w-full"
                />
              </div>
            </Form>
          )}
          {isReplyOpen && repliesIndexData && (
            <>
              <EmployeeSurveySubmissionRepliesWithEmployeeSurveySubmission
                className="mt-6"
                employeeSurveySubmission={
                  repliesIndexData.employeeSurveySubmission
                }
                mentions={[]}
                employeeSurveySubmissionReplies={
                  repliesIndexData.employeeSurveySubmissionReplies || []
                }
                refetch={repliesRefetch}
              />
            </>
          )}
        </RightSidebarToggleIconWithLabel>
      </EmployeeLayoutRightSidebar>
    </EmployeeLayout>
  );
};
